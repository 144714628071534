import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BasePath, CartCounter } from "../App";

export default function Wishlist() {

    const base_path = useContext(BasePath);
    const cartTot = useContext(CartCounter);
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [wishlist, setWishlist] = useState(true);
    
    if(!localStorage.getItem('auth_token')){
        history('/');
        toast.error("Login to view Cart", {
            position: "top-center"
        });
    }

    useEffect(() =>{
        let isMountered = true;

        axios.get(`/api/wishlist`).then(res=>{
            if(isMountered)
            {
                if(res.data.status === 200){
                    setWishlist(res.data.wishlist);
                    setLoading(false);
                }
                else if(res.data.status === 401)
                {
                    history('/');
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                } 
            }
        });
        return () => {
            isMountered = false;
        }
    },[history]);

    const addToCart = (e, prod_id, wish_id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;        
        const data = {
            product_id: prod_id,
            product_qty: 1,
        }

        axios.post(`/api/add-to-cart`, data).then(res=>{
            if(res.data.status === 201)
            {
                thisClicked.closest("tr").remove();
                axios.delete(`/api/delete-wishlistitem/${wish_id}`);
                cartTot.setCartVal(res.data.totCartVal);
                toast.success(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 409)
            {
                toast.warn(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 401)
            {
                toast.error(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 404)
            {
                toast.warn(res.data.message, {
                    position: "top-center"
                });
            }
        });

    }

    const deleteWishlistItem = (e, wishlist_id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;
        thisClicked.innerText = 'Please wait';
        
        axios.delete(`/api/delete-wishlistitem/${wishlist_id}`).then( res => {
            if(res.data.status === 200)
            {
                toast.success(res.data.message, {
                    position: "top-center"
                });
                thisClicked.closest("tr").remove();
            }else if(res.data.statuts === 404)
            {
                toast.error(res.data.message, {
                    position: "top-center"
                });
                thisClicked.innerText = "Remove from wishlist";
            }
        });
    
    }

    if(loading){
        return <p>Loading Wishlist...</p>
    }

    var wishlist_html = '';
    if(wishlist.length > 0)
    {
        wishlist_html = 
        <React.Fragment>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th className="image">Image</th>
                    <th className="product">Product Name</th>
                    <th className="price">Price</th>
                    <th className="quantity">Availability</th>
                    <th className="total">Total</th>
                    <th className="delete">×</th>
                </tr>
                </thead>
                <tbody>
                    {wishlist.map( (item, idx) => {
                        var imgpath = item.product.image;
                        imgpath = imgpath.substr(imgpath.indexOf("/"));
                        var stockavail = '';
                        if(item.product.qty > 0)
                        {
                            stockavail = <span className="badge badge-success">In Stock</span>
                        }
                        else
                        {
                            stockavail = <span className="badge badge-danger">Out of Stock</span>
                        }
                        return (
                                <tr key={idx}>
                                    <td className="text-center image"><img src={`${base_path}${imgpath}`} className="wishListImg" alt="image" /></td>
                                    <td className="list text">{item.product.name}</td>
                                    <td className="text-center list price">${item.product.selling_price}</td>
                                    <td className="text-center list quantity">
                                        {stockavail}
                                    </td>
                                    <td className="text-center list add-to">
                                        <div className="elm-btn">
                                            <Link to="#" onClick={(e) => addToCart(e, item.product.id, item.id)} className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">ADD TO CART</Link>
                                        </div>
                                    </td>
                                    <td className="text-center delete"><button onClick={ (e) => deleteWishlistItem(e, item.id)} className="btn btn-danger">x</button></td>
                                </tr>
                        )
                    }                                                    
                    )}
                </tbody>
            </table>
        </React.Fragment>
    }
    else
    {
        wishlist_html = 
        <section className="flat-cart">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>Your Wishlist is empty!</h3><br/><br/>
                    </div>
                </div>
            </div>
        </section>
    }

    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title-content text-center">
                                <div className="breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li className="blog">Wishlist</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-wish-list">
            <section className="flat-cart">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="woocommerce-tabs wc-tabs-wrapper">                         
                                <div id="tab-description" className="tab-content">
                                    
                                            {wishlist_html}
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div><br /><br />
        </React.Fragment>
    )
}
