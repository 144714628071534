import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';
import './App.css';
import RouterPage from './pages/RouterPage';

const CartCounter = createContext();
const IsAuth = createContext();
const BasePath = createContext();

axios.defaults.baseURL = "https://northshore.definiteautomation.com/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

function App() {
  const [cartVal, setCartVal] = useState(0);
  const [is_auth, setAuth] = useState(false);
  
  axios.get(`/api/check_auth`).then(res=>{
    if(res.data.status === 200){
        setAuth(true);
    }
    else if(res.data.status === 401){
        setAuth(false);
    }
  });
  
  if(is_auth)
  {
    axios.get(`/api/getCartCount`).then(res=>{
      if(res.data.status === 200){
        setCartVal(res.data.cartCount);
      } else {
        setCartVal(0);        
      }
    });
  }
  return (
    <div className="App">
      <IsAuth.Provider value={{ is_auth, setAuth }}>
        <CartCounter.Provider value={{ cartVal, setCartVal }}>
          <BasePath.Provider value={"https://northshore.definiteautomation.com/storage/"}>
            <RouterPage />
          </BasePath.Provider>
        </CartCounter.Provider>
      </IsAuth.Provider>
    </div>
  );
}

export default App;
export { CartCounter, IsAuth, BasePath };
