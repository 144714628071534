import React, { useContext } from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

import Footer from "./Footer";
import Header from './Header';

import Home from "./Home";
import About from "./About";
import Shop from "./Shop";
import Login from "./Login";
import Register from "./Register";
import ProductDetails from "./ProductDetails";
import Collection from "./Collection";
import ViewProduct from "./ViewProduct";
import ProductDetail from "./ProductDetail";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Wishlist from "./Wishlist";
import Contact from "./Contact";
import OrderCompleted from "./OrderCompleted";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import { IsAuth } from "../App";

export default function RouterPage() {
    const is_auth = useContext(IsAuth);

    return(
        <div>
            <Router>
                <Header />
                    <Routes>
                        <Route path="/" exact element={<Home />}></Route>
                        <Route path="/about" element={<About />}></Route>
                        <Route path="/contact" element={<Contact />}></Route>
                        <Route path="/shop" element={<Shop />}></Route>
                        <Route path="/product-details" element={<ProductDetails />}></Route>
                        <Route path="/collection" element={<Collection />}></Route>
                        <Route path="/collection/:slug" exact element={<ViewProduct />}></Route>
                        <Route path="/collection/:category_slug/:product_slug" exact element={<ProductDetail />}></Route>
                        <Route path="/cart" element={<Cart />}></Route>
                        <Route path="/wishlist" element={<Wishlist />}></Route>
                        <Route path="/checkout" element={<Checkout />}></Route>
                        <Route path="/thank-you/:order_id" element={<OrderCompleted />}></Route>
                        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                        <Route path="/terms-and-conditions" element={<Terms />}></Route>
                        
                        {is_auth.is_auth ?
                            <Route path="/login" element={<Navigate to="/" />}></Route> :
                            <Route path="/login" element={<Login />}></Route>
                        }
                        {is_auth.is_auth ?
                            <Route path="/register" element={<Navigate to="/" />}></Route> :
                            <Route path="/register" element={<Register />}></Route>
                        }
                        
                        {/* <Route path="/register" element={<Register />}></Route> */}
                    </Routes>
                <Footer />
                    {/* <Routes>
                        <Route path="/login" element={<Login />}></Route>
                    </Routes> */}
            </Router>
        </div>
    )
}