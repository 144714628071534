import React from "react";
import { Link } from "react-router-dom";

export default function Contact() {
    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title-content text-center">
                                <div className="breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li className="blog"><a href="#">Contact Us</a></li>
                                    </ul>
                                </div>
                                <div className="page-title-heading">
                                    <h2 className="title"><a href="#">Contact Us</a></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-contact-2">
                <section className="flat-contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="contact-icon">
                                    <Link to="3"><i className="ti-mobile" aria-hidden="true"></i></Link>
                                </div>
                                <div className="title-contact">
                                    <h3><Link to="#">Reach Us</Link></h3>
                                </div>
                                <div className="info-contact">
                                    <p><Link to="#">847-433-9122</Link></p>
                                </div>
                                <div className="text-contact">
                                    <p><Link to="#">northshorewinespirit@gmail.com</Link></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="contact-icon">
                                    <Link to="3"><i className="ti-flag-alt" aria-hidden="true"></i></Link>
                                </div>
                                <div className="title-contact">
                                    <h3><Link to="#">Address</Link></h3>
                                </div>
                                <div className="info-contact">
                                    <p><Link to="#">834 Sheridan Road Highwood IL 60040</Link></p>
                                </div>
                                <div className="text-contact">
                                    <p><Link to="#">United Stated</Link></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="contact-icon">
                                    <Link to="3"><i className="ti-email" aria-hidden="true"></i></Link>
                                </div>
                                <div className="title-contact">
                                    <h3><Link to="#">Opening Hours</Link></h3>
                                </div>
                                <div className="info-contact">
                                    <p><Link to="#">Mon-Thu: 9.30 - 21.00</Link></p>
                                </div>
                                <div className="text-contact">
                                    <p><Link to="#">Fri-Sat: 9.30 - 22.30</Link></p>
                                </div>
                                <div className="text-contact">
                                    <p><Link to="#">Sun: 10.00 - 20.00</Link></p>
                                </div>
                            </div>
                        </div> 
                    </div>
                </section>
                <section className="flat-map1">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2955.029741260807!2d-87.82022168454833!3d42.21380897919691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc02a8b270a35%3A0xf118875e34985e54!2sNorth%20Shore%20Wine%20%26%20Spirits!5e0!3m2!1sen!2sin!4v1650448392363!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy"></iframe>
                </section>
            </div>
        </React.Fragment>
    )
}