import axios from "axios";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { CartCounter, IsAuth } from "../App";

export default function Home() {

    const cartTot = useContext(CartCounter);
    const is_auth = useContext(IsAuth);
    const data = '';
    const history = useNavigate ();
    
    const navBarClick = (e) => {
        // e.preventDefault();
        var element = document.getElementById("mainnav-mobi");
        if(element){
            var mobileButton = document.getElementsByClassName("mobile-button");
            element.style.display = "none";
            $(mobileButton).removeClass('active');
        }
    }

    const logoutSubmit = (e) => {
        e.preventDefault();

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`api/logout`).then(res => {
                if(res.data.status === 200){
                    cartTot.setCartVal(0);
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('auth_name');
                    toast.success(res.data.message, {
                        position: "top-center"
                    });
                    history('/');
                }
            });
        });
    }

    var AuthButtons = '';
    if(!is_auth.is_auth){
        AuthButtons = (
            <>
            <li>
                <Link to="/login" onClick={navBarClick}>Login</Link>
            </li>
            <li>
                <Link to="/register" onClick={navBarClick}>Register</Link>
            </li>
            </>
        );
    }else{
        AuthButtons = (
            <>
            <li><Link to="#" onClick={logoutSubmit} type="button">Logout</Link></li>
            </>
        );
    }

    return(
        <React.Fragment>
            {/* <h1>total cart { cartTot.cartVal }</h1> */}
            <div id="loading-overlay">
                <div className="loader"></div>
            </div>
            <header className="style1">
                    <div id="site-header">
                        <div className="container-fluid">
                            <Link to="/" className="logo"><img src="/assets/image/logo.png" alt="image" width="129" height="37" data-retina="/assets/image/logo-2x.png" data-width="147" data-height="21" /></Link>
                            <div className="mobile-button">
                                <span></span>
                            </div>
                            <div className="mobile-cart">
                                <Link to="/cart">
                                    <i className="fa fa-2x fa-shopping-cart" aria-hidden="true"><span className="badge badge-default badge-custom">{ cartTot.cartVal }</span></i>
                                </Link>
                            </div>
                            <div className="nav-wrap ">
                                <nav id="mainnav" className="mainnav">
                                    <ul className="menu">
                                        <li className="active ">
                                            <Link to="/" onClick={navBarClick} title="">HOME</Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/about" onClick={navBarClick} title="">ABOUT</Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/collection" onClick={navBarClick} title="">COLLECTION</Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/contact" onClick={navBarClick} title="">CONTACT</Link>
                                        </li>
                                        <li className="active">
                                            <Link to="#" title="">MY ACCOUNT</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/wishlist" onClick={navBarClick} title="">My Wishlist</Link></li>
                                                <li><Link to="/cart" onClick={navBarClick}>My Cart</Link></li>
                                                {AuthButtons}
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="search clearfix">
                                <ul>
                                    <li>
                                        <Link to="/cart" className="header-search-icon1">
                                            <i className="fa fa-2x fa-shopping-cart" aria-hidden="true"><span className="badge badge-default badge-custom">{ cartTot.cartVal }</span></i>
                                        </Link>
                                    </li>
                                    {/* <li><a href="#"> <i className="ti-align-justify" aria-hidden="true"></i> </a>
                                        <ul className="sub-menu">
                                            {AuthButtons}
                                            <li><a href="projects1.html" title="">My Account</a></li>
                                            <li><a href="projects1.html" title="">My Wishlist</a></li>
                                            <li><Link to="/cart" >My Cart</Link></li>
                                            <li><a href="projects1.html" title="">Tracking Orders</a></li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
            </header>
        </React.Fragment>
    )
}