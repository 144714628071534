import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import Slider from "./Slider";
import { CartCounter, BasePath } from "../App";

export default function Home() {

    const cartTot = useContext(CartCounter);
    const base_path = useContext(BasePath);
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loadMore, setLoadMore] = useState(true);
    const [popProduct, setPopProduct] = useState([]);
    const [totProduct, setTotProduct] = useState([]);
    const [page, setPage] = useState(1);
    const productCount = popProduct.length;
    useEffect(() =>{
        let isMountered = true;
        // const product_slug = props.match.params.slug;
        axios.get(`/api/getpopproducts?page=${page}`).then(res=>{
            if(isMountered)
            {
                if(res.data.status === 200){
                    setPopProduct([...popProduct,...res.data.product_data.product.data]);
                    setTotProduct(res.data.product_data.totProduct);
                    if(res.data.product_data.product.next_page_url == null)
                    {
                        setLoadMore(false);
                    }
                    setLoading(false);
                }
                else if(res.data.status === 400)
                {
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                }
                else if(res.data.status === 404)
                {
                    history('/collection');
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                } 
            }
        });
        return () => {
            isMountered = false;
        }
    },[page, history]);

    const addToCart = (e, prod_id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;        
        const data = {
            product_id: prod_id,
            product_qty: 1,
        }

        axios.post(`/api/add-to-cart`, data).then(res=>{
            if(res.data.status === 201)
            {
                cartTot.setCartVal(res.data.totCartVal);
                $(thisClicked).closest('div').addClass('add-cart-active');
                $(thisClicked).closest('div').removeClass('add-cart');
                toast.success(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 409)
            {
                toast.warn(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 401)
            {
                toast.error(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 404)
            {
                toast.warn(res.data.message, {
                    position: "top-center"
                });
            }
        });

    }

    function updateWishList(e, product_id){
        const thisClicked = e.currentTarget; 
        axios.put(`/api/wishlist/${product_id}`).then(res=>{
            if(res.data.status === 221)
            {
                // swal('Success', res.data.message, 'success');
                toast.success(res.data.message, {
                    position: "top-center"
                });
                $(thisClicked).closest('div').removeClass('add-cart');
                $(thisClicked).closest('div').addClass('add-cart-active');
            }
            else if(res.data.status === 220)
            {
                toast.error(res.data.message, {
                    position: "top-center"
                });
                $(thisClicked).closest('div').addClass('add-cart');
                $(thisClicked).closest('div').removeClass('add-cart-active');
            }
        });
    }

    var showProductList = '';

    // if(loading){
    //     return <div id="loading-overlay">
    //                 <div className="loader"></div>
    //             </div>
    // }
    // else{
        if(productCount)
        {
            showProductList = 
            
            popProduct.map( (item, idx) => {
                var imgpath = item.image ;
                imgpath = imgpath.substr(imgpath.indexOf("/"));
                
                var addCartIcon = '';
                var wishlistIcon = '';
                if(item.cuser == '' || item.cuser == null)
                {
                    addCartIcon = <div className="add-cart">
                            <Link to="#" onClick={(e) => addToCart(e, item.id)} className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                        </div>
                }
                else
                {
                    addCartIcon = <div className="add-cart-active">
                            <Link to="#" onClick={(e) => addToCart(e, item.id)} className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                        </div>
                }

                if(item.wuser == '' || item.wuser == null)
                {
                    wishlistIcon = <div className="add-cart">
                                        <Link to="#" onClick={(e) => updateWishList(e, item.id)} className="like2"><i className="fa fa-heart-o"></i></Link>
                                    </div>
                }
                else
                {
                    wishlistIcon = <div className="add-cart-active">
                                        <Link to="#" onClick={(e) => updateWishList(e, item.id)} className="like2"><i className="fa fa-heart-o"></i></Link>
                                    </div>
                }

                return (
                    <li className="product-item sale" key={idx}>
                        <div className="product-thumb clearfix">
                            <Link to={`/collection/${item.category.slug}/${item.slug}`} className="product-thumb">
                                <img src={`${base_path}${imgpath}`} className="prodImg" alt="image" />
                            </Link>
                        </div>
                        <div className="product-info text-center clearfix">
                            <Link to={`/collection/${item.category.slug}/${item.slug}`} className="product-thumb">
                                <span className="product-title">{ item.name }</span>
                            </Link>
                            
                            <div className="price">
                                <ins>
                                    <span className="amount">${ item.selling_price } </span>
                                    <del>${ item.original_price }</del>
                                </ins>
                            </div>
                            {/* <div className="elm-btn">
                            <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                            </div> */}
                        </div>
                        <div className="product-review">
                                {/* <div className="add-cart">
                                    <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                </div> */}
                                {wishlistIcon}
                                {addCartIcon}
                        </div>
                    </li>
                    
                )
            });

        }
        else
        {
            showProductList = <li className="product-item sale">No product found in popular collection.</li>
        }
        
    // }
    return(
        <React.Fragment>
            <Slider />
            <div className="main-homepage-1">    
                <section className="flat-about style1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7 col-md-7">
                                <div className="image-single">
                                    <img src="/assets/image/homepage144.png" alt="image" />
                                </div>
                                </div> 
                                <div className="col-lg-5 col-md-5">
                                    <div className="flat-divider margin-top50px1"></div>
                                    <div className="flat-textbox">
                                        <h3 className="textbox-title"><Link to="#"> Our Form Northshore </Link></h3>
                                        <h1 className="textbox-main"><Link to="#"> Welcome To NorthShore Wine & Spirits </Link></h1>
                                        <p>We are operating as a family owned business, our goal is to greet the customer like family. We provide services as Online shopping, Curbside pickup, In-Store purchase, Delivery.</p>
                                    </div>
                                    <div className="flat-divider margin-top24px"></div>
                                    <div className="information">
                                        <div className="information-address">
                                            <span>Address:</span>
                                            <span className="information-content">834 Sheridan Road Highwood IL 60040</span>
                                        </div>
                                        <div className="information-hotline information-bottom">
                                            <span>Phone:</span>
                                            <span className="information-content">847-433-9122</span>
                                        </div>
                                        <div className="information-open information-bottom">
                                            <span>We're Open</span>
                                            <span className="information-content">Mon-Thu: 9.30 - 21.00</span>
                                            <span className="information-content">Fri-Sat: 9.30 - 22.30</span>
                                            <span className="information-content">Sun: 10.00 - 20.00</span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                </section>
                <section className="flat-our-product our-product-new">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="title-section">
                                        <h3 className="our-product-title"><Link to="#"> Our Products </Link></h3>
                                        <h1 className="our-product-main"><Link to="#"> Popular This Month </Link></h1>
                                        <div className="our-product-image">
                                            <img src="/assets/image/homepage14.png" alt="image" />
                                        </div>
                                    </div> 
                                </div>
                                <div className="product-content product-fourcolumn clearfix">
                                <ul className="product clearfix">
                                    {showProductList}
                                </ul>
                                </div> 
                                {loadMore ? 
                                    <div className="elm-btn">
                                        <Link to="#" onClick={(e) => { e.currentTarget.innerText='Please Wait...'; setPage(page + 1)}} className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">VIEW MORE</Link>
                                    </div>
                                : null }
                            </div> 
                        </div> 
                </section> 
                <section className="flat-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="title-section">
                                    <h1 className="our-product-main"><Link to="#"> Discover Our Wines Collection </Link></h1>
                                    <div className="our-product-image-background">
                                        <img src="/assets/image/test.png" alt="image" />
                                    </div>
                                </div>
                                <p className="banner-content"><Link to="#">Defined by our Region, Crafted by our Winemakers</Link></p>
                                <div className="elm-btn">
                                    <Link to="/collection/wine" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">SEE MORE</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="flat-benefil">
                    <div className="container">
                        <div className="row text-left">
                            <div className="col-lg-4">
                                <div className="benefil-inner">
                                    <div className="icon-wrap">
                                        <i className="fa fa-plane" aria-hidden="true"></i>
                                    </div>
                                    <div className="text-wrap">
                                        <h5 className="heading"><Link to=""> Free Shipping</Link></h5>
                                        <p className="desc">Free shipping on order over $300</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 ">
                                <div className="benefil-inner inner-center">
                                    <div className="icon-wrap ">
                                        <i className="fa fa-usd" aria-hidden="true"></i>
                                    </div>
                                    <div className="text-wrap">
                                        <h5 className="heading"><Link to="">Cash on Delivery</Link></h5>
                                        <p className="desc">The internet tend to repea:</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="benefil-inner inner-bottom">
                                    <div className="icon-wrap ">
                                        <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </div>
                                    <div className="text-wrap">
                                        <h5 className="heading"><Link to="">Money Safe</Link></h5>
                                        <p className="desc">30 day money guarantee</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            
        </React.Fragment>
    )
}