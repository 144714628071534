import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import ReactDOM from 'react-dom';
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartCounter, BasePath } from "../App";

export default function Cart() {

    const base_path = useContext(BasePath);
    const cartTot = useContext(CartCounter);
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState(true);
    var totalCartPrice = 0;
    var taxAmt = 0;
    var grandTotal = 0;

    if(!localStorage.getItem('auth_token')){
        history('/');
        toast.error("login to view cart", {
            position: "top-center"
        });
    }

    if(cart.length < 1){
        history('/cart');
        toast.error("You don't have any item in your Cart!", {
            position: "top-center"
        });
    }
    const [checkoutInput, setCheckoutInput] = useState({
        firstname: '',
        lastname: '',
        address: '',
        city: '',
        postcode: '',
        email: '',
        phone: '',
        ordernote: '',
    });

    const [error, setError] = useState([]); 

    useEffect(() =>{
        let isMountered = true;

        axios.get(`/api/cart`).then(res=>{
            if(isMountered)
            {
                if(res.data.status === 200){
                    setCart(res.data.cart);
                    setLoading(false);
                }
                else if(res.data.status === 401)
                {
                    history('/');
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                } 
            }
        });
        return () => {
            isMountered = false;
        }
    },[history]);

    const handleInput = (e) => {
        e.persist();
        setCheckoutInput({...checkoutInput, [e.target.name]: e.target.value})
    }

    const orderinfo_data = {
        firstname: checkoutInput.firstname,
        lastname: checkoutInput.lastname,
        address: checkoutInput.address,
        city: checkoutInput.city,
        postcode: checkoutInput.postcode,
        email: checkoutInput.email,
        phone: checkoutInput.phone,
        ordernote: checkoutInput.ordernote,
        payment_mode: 'PayPal',
        payment_id: '',
    }

    // paypal
    const PayPalButton = window.paypal.Buttons.driver("react", {React, ReactDOM});
    const createOrder = (data, actions) =>{
        return actions.order.create({
            purchase_units:[
                {
                    amount: {
                        value: grandTotal,
                    },
                },
            ],
        });
    };
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function(details) {
            orderinfo_data.payment_id = details.id;
            
            axios.post(`/api/place-order`, orderinfo_data).then(res=>{
                if(res.data.status === 200)
                {
                    cartTot.setCartVal(0);
                    toast.success(res.data.message, {
                        position: "top-center"
                    });
                    setError([]);
                    history(`/thank-you/${res.data.order_id}`);
                }
                else if(res.data.status === 422)
                {
                    toast.error("All field are mandetory", {
                        position: "top-center"
                    });
                    setError(res.data.errors);
                }
            });
        });
    };
    // paypal end

    const submitOrder = (e, payment_mode) => {
        e.preventDefault();

        const data = {
            firstname: checkoutInput.firstname,
            lastname: checkoutInput.lastname,
            address: checkoutInput.address,
            city: checkoutInput.city,
            postcode: checkoutInput.postcode,
            email: checkoutInput.email,
            phone: checkoutInput.phone,
            ordernote: checkoutInput.ordernote,
            payment_mode: payment_mode,
            payment_id: '',
        }

        const thisClicked = e.currentTarget;
        thisClicked.innerText = 'Please Wait...';

        switch (payment_mode) {
            case 'cod':
                axios.post(`/api/place-order`, data).then(res=>{
                    if(res.data.status === 200)
                    {
                        cartTot.setCartVal(0);
                        toast.success(res.data.message, {
                            position: "top-center"
                        });
                        setError([]);
                        history(`/thank-you/${res.data.order_id}`);
                    }
                    else if(res.data.status === 422)
                    {
                        toast.error("All field are mandetory", {
                            position: "top-center"
                        });
                        thisClicked.innerText = "Place Order";
                        setError(res.data.errors);
                    }
                });
                break;
            case 'paypal':
                axios.post(`/api/validate-order`, data).then(res=>{
                    if(res.data.status === 200)
                    {
                        setError([]);
                        var myModal = new window.bootstrap.Modal(document.getElementById('paypalModal'));
                        myModal.show();
                    }
                    else if(res.data.status === 422)
                    {
                        toast.error("All field are mandetory", {
                            position: "top-center"
                        });
                        thisClicked.innerText = "Place Order";
                        setError(res.data.errors);
                    }
                });
                break;
            default:
                break;
        }


        
    }

    if(loading){
        return <p>Loading...</p>
    }

    return (
        <React.Fragment>
        <div id="paypalModal" tabIndex="-1" className="modal" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Online Payment Mode</h4>
                    </div>
                    <div className="modal-body">
                        <PayPalButton 
                            createOrder={(data, actions) => createOrder(data, actions)}
                            onApprove={(data, actions) => onApprove(data, actions)}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="main-shop-cart">
            <section className="flat-cart">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="woocommerce-tabs wc-tabs-wrapper">
                                <ul className="tabs">
                                    <li><Link className="tab active" data-id ='#tab-description' to="#"><i className=" icon_ribbon" aria-hidden="true"></i>CHECK OUT</Link></li>
                                </ul>
                                <div className="cart-wrap">
                                    <div id="tab-description" className="tab-content">
                                        <div className="check-out">
                                            <h3 className="heading-check">Billing Address</h3>
                                            <form className="form-checkout text-left" >
                                                <div className="row-input-wrap clearfix">
                                                    <div className="input-wrap float-left-50 pd-right-10">
                                                        <label>First name<span> *</span></label>
                                                        <input type="text" name="firstname" onChange={handleInput} value={checkoutInput.firstname} className="width-100" />
                                                        <p className="text-danger">{error.firstname}</p>
                                                    </div>
                                                    <div className="input-wrap float-right-50 pd-left-10">
                                                        <label>Last name<span> *</span></label>
                                                        <input type="text" name="lastname" onChange={handleInput} value={checkoutInput.lastname} className="width-100" />
                                                        <p className="text-danger">{error.lastname}</p>
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    <label>Address<span> *</span></label>
                                                    <textarea name="address" className="mg-bottom-25 width-100" onChange={handleInput} value={checkoutInput.address}></textarea>
                                                    <p className="text-danger">{error.address}</p>
                                                </div>
                                                <div className="row-input-wrap clearfix">
                                                <div className="input-wrap float-left-50 pd-right-10">
                                                        <label>TOWN/CITY <span> *</span></label>
                                                        <input type="text" name="city" className="mg-bottom-25 width-100" onChange={handleInput} value={checkoutInput.city} />
                                                        <p className="text-danger">{error.city}</p>
                                                    </div>
                                                    <div className="input-wrap float-right-50 pd-left-10">
                                                        <label>POSTCODE/ZIP <span> *</span></label>
                                                        <input type="text" name="postcode" className="mg-bottom-25 width-100" onChange={handleInput} value={checkoutInput.postcode} />
                                                        <p className="text-danger">{error.postcode}</p>
                                                    </div>
                                                </div>
                                                <div className="row-input-wrap clearfix">
                                                    <div className="input-wrap float-left-50 pd-right-10">
                                                        <label>Email Address <span> *</span></label>
                                                        <input type="text" name="email" className="mg-bottom-25 width-100" onChange={handleInput} value={checkoutInput.email} />
                                                        <p className="text-danger">{error.email}</p>
                                                    </div>
                                                    <div className="input-wrap float-right-50 pd-left-10">
                                                        <label>Phone Number <span> *</span></label>
                                                        <input type="text" name="phone" className="mg-bottom-25 width-100" onChange={handleInput} value={checkoutInput.phone} />
                                                        <p className="text-danger">{error.phone}</p>
                                                    </div>
                                                </div>
                                                <div className="input-wrap ">
                                                    <label>Order note</label>
                                                    <textarea name="ordernote" onChange={handleInput} value={checkoutInput.ordernote} ></textarea>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="review-order">
                                            <h3>Your Order</h3>
                                            <table className="order-table text-left" width="100%">
                                                <tbody>
                                                    <tr className="cart-subtotal cart-title-details">
                                                        <td className="product-name">Product</td>
                                                        <td className="total" data-title="Subtotal"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol"></span>Total</span></td>
                                                    </tr>
                                                    {cart.map( (item, idx) => {
                                                    var imgpath = item.product.image;
                                                    imgpath = imgpath.substr(imgpath.indexOf("/"));
                                                    totalCartPrice += item.product.selling_price * item.product_qty;
                                                    taxAmt = totalCartPrice * 10 /100;
                                                    grandTotal = totalCartPrice + taxAmt;
                                                    return (                                                        
                                                        <tr className="cart-subtotal" key={idx}>
                                                            <td className="product-name">{item.product.name} ({item.product_qty}x)</td>
                                                            <td className="total" data-title="Subtotal"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">$</span>{item.product.selling_price * item.product_qty} </span></td>
                                                        </tr>
                                                        )}
                                                    )}
                                                    <tr className="order-total">
                                                        <td  className="product-name">Sub Total </td>
                                                        <td className="total text-d90000 font-weight-500" data-title="Total"> <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol ">$</span>{parseFloat(totalCartPrice).toFixed(2)}</span> </td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <td className="product-name">Tax (10%)</td>
                                                        <td className="total" data-title="Total">${parseFloat(taxAmt).toFixed(2)}</td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <td  className="title product-name">ORDER TOTAL </td>
                                                        <td className="title total text-d90000 font-weight-500" data-title="Total"> <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">$</span>{parseFloat(grandTotal).toFixed(2)}</span> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="wrap-check mg-top-20">
                                                
                                                <div className="wc-proceed-to-checkout text-center pd-top-20">
                                                    <div className="elm-btn">
                                                        <button type="button" onClick={ (e) => submitOrder(e, 'cod')} className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">Place Order With COD</button>
                                                        <button type="button" onClick={ (e) => submitOrder(e, 'paypal')} className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">Place Order With PayPal</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <br /><br />
        
        </React.Fragment>
    )
}