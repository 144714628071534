import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartCounter, BasePath } from "../App";

export default function Cart() {

    const cartTot = useContext(CartCounter);
    const base_path = useContext(BasePath);
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState(true);
    var totalCartPrice = 0;
    var taxAmt = 0;
    var grandTotal = 0;

    if(!localStorage.getItem('auth_token')){
        history('/');
        toast.error("login to view cart", {
            position: "top-center"
        });
    }

    useEffect(() =>{
        let isMountered = true;

        axios.get(`/api/cart`).then(res=>{
            if(isMountered)
            {
                if(res.data.status === 200){
                    setCart(res.data.cart);
                    setLoading(false);
                }
                else if(res.data.status === 401)
                {
                    history('/');
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                } 
            }
        });
        return () => {
            isMountered = false;
        }
    },[history]);

    const handleDecrement = (cart_id) => {
        setCart(cart =>
            cart.map( (item) =>
                cart_id === item.id ? {...item, product_qty: item.product_qty - (item.product_qty > 1 ? 1:0)} : item
            )
        );
        updateCartQuantity(cart_id,'dec');
    }

    const handleIncrement = (cart_id) => {
        setCart(cart =>
            cart.map( (item) =>
                cart_id === item.id ? {...item, product_qty: item.product_qty + (item.product_qty < 10 ? 1:0)} : item
            )
        );
        updateCartQuantity(cart_id,'inc');
    }

    function updateCartQuantity(card_id, scope){
        axios.put(`/api/cart-updatequantity/${card_id}/${scope}`).then(res=>{
            if(res.data.status === 200)
            {
                cartTot.setCartVal(res.data.totCartVal);
                // swal('Success', res.data.message, 'success');
            }
        });
    }

    const deleteCartItem = (e, cart_id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;
        thisClicked.innerText = '...';
        
        axios.delete(`/api/delete-cartitem/${cart_id}`).then( res => {
            if(res.data.status === 200)
            {
                setCart(cart =>
                    cart.map( (item) =>
                        cart_id === item.id ? {...item, product_qty: 0} : item
                    )
                );
                cartTot.setCartVal(res.data.totCartVal);
                toast.success(res.data.message, {
                    position: "top-center"
                });
                thisClicked.closest("tr").remove();
            }else if(res.data.statuts === 404)
            {
                toast.error(res.data.message, {
                    position: "top-center"
                });
                thisClicked.innerText = "<i className='fa fa-remove'></i>";
            }
        });
    
    }

    if(loading){
        return <p>Loading Cart...</p>
    }

    var cart_html = '';
    if(cart.length > 0)
    {
        cart_html = 
        <React.Fragment>
        <table className="table table-bordered">
            <thead>
            <tr>
                <th>Image</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Remove</th>
            </tr>
            </thead>
            <tbody>
                {cart.map( (item, idx) => {
                    var imgpath = item.product.image;
                    var cateimgpath = item.product.category.thumbnail ;
                    imgpath = imgpath.substr(imgpath.indexOf("/"));
                    cateimgpath = cateimgpath.substr(cateimgpath.indexOf("/"));

                    totalCartPrice += item.product.selling_price * item.product_qty;
                    taxAmt = totalCartPrice * 10 /100;
                    grandTotal = totalCartPrice + taxAmt;
                    return (                                                        
                        <tr key={idx}>
                            <td className="text-center image">
                                <img src={`${base_path}${imgpath}`} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src=`${base_path}${cateimgpath}`;
                                }}
                                className="cartImg" alt="image" />
                            </td>
                            <td className="list text">{item.product.name}</td>
                            <td className="text-center list price">${item.product.selling_price}</td>
                            <td className="text-center list quantity">
                                <ul className="quantity-menu">
                                    <li onClick={() => handleDecrement(item.id)} className="p-3"><i className="fa fa-minus-circle" aria-hidden="true"></i></li>
                                    <li> {item.product_qty} </li>
                                    <li onClick={() => handleIncrement(item.id)} className="p-3"><i className="fa fa-plus-circle" aria-hidden="true"></i></li>
                                </ul>
                            </td>
                            <td className="text-center list price">${item.product.selling_price * item.product_qty}</td>
                            <td className="text-center delete"><button className="btn" type="button" onClick={ (e) => deleteCartItem(e, item.id)}><i className="fa fa-remove"></i></button></td>
                        </tr>
                    )
                }                                                    
                )}
            </tbody>
        </table>
        <div className="row">
            <div className="wrap-discount-estimate-cart">
                <div className="col-lg-8 col-md-8">
                    <div className="btn-continue">
                        <div className="elm-btn">
                            <Link to="/collection" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">CONTINUE SHOPPING</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 cart">
                    <h2 className="cart-heading">Cart Total</h2>
                    <div className="wrap-cart">
                        <div className="sub-total">
                            <span className="text-left">SUB TOTAL</span>
                            <p className="price">${parseFloat(totalCartPrice).toFixed(2)}</p>
                        </div>
                        <div className="sub-total">
                            <span className="text-left">Tax (10%)</span>
                            <p className="price">${parseFloat(taxAmt).toFixed(2)}</p>
                        </div>
                        <div className="shipping">
                            <span className="text-left">SHIPPING</span>
                            <p className="price">Free</p>
                        </div>
                        <div className="totall">
                            <span className="text-left">TOTAL</span>
                            <p className="price">${parseFloat(grandTotal).toFixed(2)}</p>
                        </div>
                    </div>
                    <div className="elm-btn">
                        <Link to="/checkout" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">PROCEED TO CHECKOUT</Link>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    }
    else
    {
        cart_html = 
        <section className="flat-cart">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>Your shopping cart is empty!</h3><br/><br/>
                    </div>
                </div>
            </div>
        </section>
    }

    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title-content text-center">
                                <div className="breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li className="blog">Cart</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-shop-cart">
                <section className="flat-cart">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="woocommerce-tabs wc-tabs-wrapper">
                                    <ul className="tabs">
                                        <li><Link className="tab active" data-id ='#tab-description' to="#"><i className="  icon_bag" aria-hidden="true"></i>SHOPPING CART</Link></li>
                                    </ul>
                                    <div className="cart-wrap">
                                        <div id="tab-description" className="tab-content">
                                            {cart_html}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div><br /><br />
        </React.Fragment>
    )
}
