import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { CartCounter, BasePath } from "../App";

export default function ViewProduct() {

    const base_path = useContext(BasePath);
    const cartTot = useContext(CartCounter);
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [totProduct, setTotProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [allCategory, setAllCategory] = useState([]);
    const [recProd, setRecProd] = useState([]);
    const { slug } = useParams();
    const [page, setPage] = useState(1);

    const productCount = product.length;
    
    useEffect(() =>{
        let isMountered = true;
        // const product_slug = props.match.params.slug;
        axios.get(`/api/getproducts/${slug}?page=${page}`).then(res=>{
            if(isMountered)
            {
                if(res.data.status === 200){
                    setProduct([...product,...res.data.product_data.product.data]);
                    setCategory(res.data.product_data.category);
                    setTotProduct(res.data.product_data.totProduct);
                    setAllCategory(res.data.product_data.allCategory);
                    setRecProd(res.data.product_data.recentProduuct);
                    setLoading(false);
                }
                else if(res.data.status === 400)
                {
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                }
                else if(res.data.status === 404)
                {
                    history('/collection');
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                } 
            }
        });
        return () => {
            isMountered = false;
        }
    },[slug, page, history]);

    const addToCart = (e, prod_id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;        
        const data = {
            product_id: prod_id,
            product_qty: 1,
        }

        axios.post(`/api/add-to-cart`, data).then(res=>{
            if(res.data.status === 201)
            {
                $(thisClicked).closest('div').addClass('add-cart-active');
                $(thisClicked).closest('div').removeClass('add-cart');
                cartTot.setCartVal(res.data.totCartVal);
                toast.success(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 409)
            {
                toast.warn(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 401)
            {
                toast.error(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 404)
            {
                toast.warn(res.data.message, {
                    position: "top-center"
                });
            }
        });

    }

    function updateWishList(e, product_id){
        const thisClicked = e.currentTarget; 
        axios.put(`/api/wishlist/${product_id}`).then(res=>{
            if(res.data.status === 221)
            {
                // swal('Success', res.data.message, 'success');
                toast.success(res.data.message, {
                    position: "top-center"
                });
                $(thisClicked).closest('div').removeClass('add-cart');
                $(thisClicked).closest('div').addClass('add-cart-active');
            }
            else if(res.data.status === 220)
            {
                toast.error(res.data.message, {
                    position: "top-center"
                });
                $(thisClicked).closest('div').addClass('add-cart');
                $(thisClicked).closest('div').removeClass('add-cart-active');
            }
        });
    }

    if(loading){
        return <p>Loading Products...</p>
    }
    else{
        var showProductList = '';
        var showAllCategory = '';
        var recentProduct = '';
        showAllCategory = allCategory.map( (item, idx) => {
            var cimgpath = item.thumbnail;
            cimgpath = cimgpath.substr(cimgpath.indexOf("/"));
            return (
                <li className="thumb-new-categories categorie text-left" key={idx}>
                    <Link to={`/collection/${item.slug}`} onClick={(e) => {setProduct([]); setLoading(true); setPage(1)}}>
                        {item.name}
                        <span className="categories-time"> ({item.category_product_count})</span>
                    </Link>
                    <img src="/assets/image/dot.jpg" alt="image" />
                </li>
            )
        });

        recentProduct = recProd.map( (item, idx) => {
            var rimgpath = item.image;
            rimgpath = rimgpath.substr(rimgpath.indexOf("/"));
            return (
                <li className="thumb-new" key={idx}>
                    <Link to={`/collection/${item.category.slug}/${item.slug}`}>
                        <div className="thumb-new-image">
                            <img src={`${base_path}${rimgpath}`} className="recProdImg" alt="image" />
                        </div>  
                        <div className="thumb-new-content">
                            <ul>
                                <li> <span className="thumb-new-title">{item.name}</span> </li>
                                <li> <span className="thumb-new-price">${item.selling_price}</span> </li>
                            </ul>
                        </div>
                    </Link>
                </li>
            )
        });

        if(productCount)
        {
            showProductList = 
            <InfiniteScroll 
                dataLength= {totProduct} 
                next={() => setPage(page + 1)}
                hasMore={true}
                // loader={<h4>Loading...</h4>}
            >
            {
                product.map( (item, idx) => {
                    var imgpath = item.image ;
                    var cateimgpath = item.category.thumbnail ;
                    imgpath = imgpath.substr(imgpath.indexOf("/"));
                    cateimgpath = cateimgpath.substr(cateimgpath.indexOf("/"));
                    
                    var addCartIcon = '';
                    var wishlistIcon = '';
                    var itemPrice = '';
                    if(item.cuser == '' || item.cuser == null)
                    {
                        addCartIcon = <div className="add-cart">
                                <Link to="#" onClick={(e) => addToCart(e, item.id)} className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                            </div>
                    }
                    else
                    {
                        addCartIcon = <div className="add-cart-active">
                                <Link to="#" onClick={(e) => addToCart(e, item.id)} className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                            </div>
                    }

                    if(item.wuser == '' || item.wuser == null)
                    {
                        wishlistIcon = <div className="add-cart">
                                            <Link to="#" onClick={(e) => updateWishList(e, item.id)} className="like2"><i className="fa fa-heart-o"></i></Link>
                                        </div>
                    }
                    else
                    {
                        wishlistIcon = <div className="add-cart-active">
                                            <Link to="#" onClick={(e) => updateWishList(e, item.id)} className="like2"><i className="fa fa-heart-o"></i></Link>
                                        </div>
                    }

                    if(item.original_price == '' || item.original_price == null)
                    {
                        itemPrice =
                        <ins>
                            <span className="amount">${ item.selling_price } </span>
                        </ins>
                    }
                    else{
                        itemPrice =
                        <ins>
                            <span className="amount">${ item.selling_price } </span>
                            <del>${ item.original_price }</del>
                        </ins>
                    }
                    return (
                        <li className="product-item sale" key={idx}>
                            <div className="product-thumb clearfix">
                                <Link to={`/collection/${item.category.slug}/${item.slug}`} className="product-thumb">
                                    <img src={`${base_path}${imgpath}`} 
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src=`${base_path}${cateimgpath}`;
                                        }} className="prodImg" alt="image" />
                                </Link>
                            </div>
                            <div className="product-info text-center clearfix">
                                <Link to={`/collection/${item.category.slug}/${item.slug}`} className="product-thumb">
                                    <span className="product-title">{ item.name } - { item.size }</span>
                                </Link>
                                
                                <div className="price">
                                    {itemPrice}
                                </div>
                                <div className="elm-btn">
                                <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                                </div>
                            </div>
                            <div className="product-review">
                                    {/* <div className="add-cart">
                                        <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                    </div> */}
                                    {wishlistIcon}
                                    {addCartIcon}
                            </div>
                        </li>
                        
                    )
                })
            }
            </InfiniteScroll>
        }
        else
        {
            showProductList = <li className="product-item sale">No product found in {category.name} collection.</li>
        }
    }


    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-title-content text-center">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/collection">Collection</Link></li>
                                            <li className="blog">{category.name}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="main-shop-right grid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="product-content clearfix shop-right-grid">
                                <ul className="product style2 isotope-product clearfix">
                                    {showProductList}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 sidebar">
                            <div className="sidebar-inner clearfix">
                                <div className="widget widget-categories">
                                    <h2 className="widget-title text-center">Categories</h2>
                                    <ul>  
                                        {showAllCategory}
                                    </ul>
                                </div>
                                <div className="widget widget-recent clearfix">
                                    <h2 className="widget-title text-center">Recent Product</h2> 
                                    <ul>
                                        {recentProduct} 
                                    </ul>    
                                </div>                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
        </React.Fragment>
    )
}