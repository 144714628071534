import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function OrderCompleted() {

    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [orderDetail, setOrderDetail] = useState(true);
    const [orderItems, setOrderItem] = useState([]);
    const { order_id } = useParams();
    var totalCartPrice = 0;
    var taxAmt = 0;
    var grandTotal = 0;
    const [grandTotal1, setGrandTotal] = useState(0);
    
    if(!localStorage.getItem('auth_token')){
        history('/');
        toast.error("Login to view Cart", {
            position: "top-center"
        });
    }

    useEffect(() =>{
        let isMountered = true;

        axios.get(`/api/order-detail/${order_id}`).then(res=>{
            if(isMountered)
            {
                if(res.data.status === 200){
                    setOrderDetail(res.data.order_data);
                    setOrderItem(res.data.order_item_data);
                    setLoading(false);
                }
                else if(res.data.status === 420)
                {
                    history('/');
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                }
                else if(res.data.status === 401)
                {
                    history('/');
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                }
            }
        });
        return () => {
            isMountered = false;
        }
    },[history]);

    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title-content text-center">
                                <div className="breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li className="blog"><Link to="#">Order Completed</Link></li>
                                    </ul>
                                </div>
                                <div className="page-title-heading">
                                    <h2 className="title"><Link to="#">Order Completed</Link></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-shop-cart">
                <section className="flat-cart">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-left">
                                <div className="woocommerce-tabs wc-tabs-wrapper">
                                    <div className="order-complete">
                                        <p className="heading-order">Thank you. Your order has been received</p>
                                        <div className="order-wrap">
                                            <ul className="order-menu text-left">
                                                <li>
                                                    <h3>Order number:</h3>
                                                    <p>#{orderDetail.id}</p>
                                                </li>
                                                <li>
                                                    <h3>Date:</h3>
                                                    <p>{orderDetail.created_at}</p>
                                                </li>
                                                <li>
                                                    <h3>Tracking ID:</h3>
                                                    <p>{orderDetail.tracking_no}</p>
                                                </li>
                                                <li>
                                                    <h3>Payment Method:</h3>
                                                    <p>{orderDetail.payment_mode}</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="order-details">
                                            <h1><Link to="#">Order details</Link></h1>
                                            <div className="order-table">
                                                <table className="order-table" >
                                                <tbody>
                                                    <tr className="cart-subtotal">
                                                        <td className="product-name">PRODUCT</td>
                                                        <td className="total" data-title="Subtotal"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">$</span>TOTAL</span></td>
                                                    </tr>
                                                    {orderItems.map( (item, idx) => {
                                                        totalCartPrice += item.price * item.qty;
                                                        taxAmt = totalCartPrice * 10 /100;
                                                        grandTotal = totalCartPrice + taxAmt;
                                                    return ( 
                                                            <tr className="cart-subtotal" key={idx}>
                                                                <td className="product-name">{item.product.name}</td>
                                                                <td className="total" data-title="Subtotal"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">$</span>{item.price}</span></td>
                                                            </tr>
                                                        )}
                                                    )}
                                                    <tr className="order-total">
                                                        <td  className="product-name">Sub Total </td>
                                                        <td className="total text-d90000 font-weight-500"  data-title="Total"> <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol ">$</span>{totalCartPrice}</span> </td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <td className="product-name">Tax</td>
                                                        <td className="total" data-title="Total">$ {taxAmt}</td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <td className="product-name">Payment methob:</td>
                                                        <td className="total" data-title="Total"> {orderDetail.payment_mode}</td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <td  className="title product-name">ORDER TOTAL </td>
                                                        <td className="total text-d90000 font-weight-500" data-title="Total"> <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">$</span>{grandTotal}</span> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div><br /><br />
        </React.Fragment>
    )
}