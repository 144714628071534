import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Register() {

    const history = useNavigate();
    const [registerInput, setRegister] = useState({
        name: '',
        email: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setRegister({...registerInput, [e.target.name]: e.target.value })
    }

    const registerSubmit = (e) => {
        e.preventDefault();

        const data = {
            name: registerInput.name,
            email: registerInput.email,
            password: registerInput.password
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/register`, data).then(res => {
                if(res.data.status === 200){
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    toast.success(res.data.message, {
                        position: "top-center"
                    });
                    history('/');
                }else{
                    setRegister({...registerInput, error_list: res.data.validation_errors});
                }
            });
        });
    }

    return(
        <React.Fragment>
            <div className="main-about">
                <section className="flat-about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="title-section">
                                    <h3 className="our-product-title"><Link to="#"> Sign Up </Link></h3>
                                    <h1 className="our-product-main"><Link to="#"> Registration Form </Link></h1>
                                    <div className="our-product-image">
                                        <img src="./assets/image/homepage14.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img src="./assets/image/login.webp" className="img-responsive" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 text-left">
                                <br/>
                                <form onSubmit={registerSubmit}>
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input type="text" name="name" onChange={handleInput} value={registerInput.name} className="form-control" id="txtFname" placeholder="Enter Full Name" />
                                        <span className="text-danger">{registerInput.error_list.name}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Email address</label>
                                        <input type="email" name="email" onChange={handleInput} value={registerInput.email} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                                        <span className="text-danger">{registerInput.error_list.email}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" name="password" onChange={handleInput} value={registerInput.password} className="form-control" id="exampleInputPassword1" placeholder="Password" />
                                        <span className="text-danger">{registerInput.error_list.password}</span>
                                    </div>
                                    <button type="submit" className="btn btn-primary form-control">Register</button>
                                    <h4 className="text-center">Or</h4>
                                    <h4 className="text-center">Already have an account <Link to="/login">Login here</Link></h4>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}