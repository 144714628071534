import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartCounter, BasePath } from "../App";

export default function ProductDetail() {

    const base_path = useContext(BasePath);
    const cartTot = useContext(CartCounter);
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    
    const { category_slug } = useParams();
    const { product_slug } = useParams();
    const [quantity, setQuantity] = useState(1);
    
    useEffect(() =>{
        let isMountered = true;
        axios.get(`/api/viewproduct/${category_slug}/${product_slug}`).then(res=>{
            if(isMountered)
            {
                if(res.data.status === 200){
                    setProduct(res.data.product);
                    setLoading(false);
                }
                else if(res.data.status === 400)
                {
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                }
                else if(res.data.status === 404)
                {
                    history('/collection');
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                } 
            }
        });
        return () => {
            isMountered = false;
        }
    },[category_slug, product_slug, history]);

    const handleDecrement = () => {
        if(quantity > 1)
        {    
            setQuantity(prevCount => prevCount - 1);
        }
    }
    const handleIncrement = () => {
        if(quantity < 10)
        {
            setQuantity(prevCount => prevCount + 1);
        }
    }

    const submitAddtocart = (e) => {
        e.preventDefault();

        const data = {
            product_id: product.id,
            product_qty: quantity,
        }

        axios.post(`/api/add-to-cart`, data).then(res=>{
            if(res.data.status === 201)
            {
                cartTot.setCartVal(res.data.totCartVal);
                toast.success(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 409)
            {
                toast.warn(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 401)
            {
                toast.error(res.data.message, {
                    position: "top-center"
                });
            } else if(res.data.status === 404)
            {
                toast.warn(res.data.message, {
                    position: "top-center"
                });
            }
        });

    }

    function updateWishList(e, product_id){
        const thisClicked = e.currentTarget; 
        axios.put(`/api/wishlist/${product_id}`).then(res=>{
            if(res.data.status === 221)
            {
                // swal('Success', res.data.message, 'success');
                $(thisClicked).closest('div').removeClass('heart');
                $(thisClicked).closest('div').addClass('heart-active');
            }
            else if(res.data.status === 220)
            {
                $(thisClicked).closest('div').addClass('heart');
                $(thisClicked).closest('div').removeClass('heart-active');
            }
        });
    }

    if(loading){
        return <p>Loading Product Detail...</p>
    }
    else{
        var imgpath = product.image ;
        var cateimgpath = product.category.thumbnail ;
        imgpath = imgpath.substr(imgpath.indexOf("/"));
        cateimgpath = cateimgpath.substr(cateimgpath.indexOf("/"));

        var stockavail = '';
        var stockavailbadge = '';
        var wishlistDiv = '';
        var itemPrice = '';

        if(product.wuser == '' || product.wuser == null)
        {
            wishlistDiv = <div className="heart">
                <Link to="#" onClick={(e) => updateWishList(e, product.id)}>
                    <i className="fa fa-heart" aria-hidden="true"></i>
                </Link>
            </div>
        }
        else
        {
            wishlistDiv = <div className="heart-active">
                <Link to="#" onClick={(e) => updateWishList(e, product.id)}>
                    <i className="fa fa-heart" aria-hidden="true"></i>
                </Link>
            </div>
        }

        if(product.qty > 0)
        {
            stockavailbadge = <h6><span className="badge badge-success">In Stock</span></h6>;
            stockavail = <div className="content-cart clearfix">
                            <div className="quantity">
                                <div className="col-md-2 col-xs-3">
                                    <div className="input-group">
                                        <span className="input-group-btn">
                                            <button type="button" className="quantity-left-minus btn btn-default" onClick={handleDecrement}>
                                            <span className="glyphicon glyphicon-minus"></span>
                                            </button>
                                        </span>
                                        <div className="form-control">{quantity}</div>
                                        <span className="input-group-btn">
                                            <button type="button" className="quantity-right-plus btn btn-default" onClick={handleIncrement}>
                                                <span className="glyphicon glyphicon-plus"></span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="elm-btn">
                                <button type="button" onClick={submitAddtocart} className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontall"><i className="fa fa-shopping-cart" aria-hidden="true"></i> ADD TO CART</button>
                            </div>
                            {wishlistDiv}
                            
                        </div>
                        ;
        }
        else
        {
            stockavailbadge = <h6><span className="badge badge-danger">Out of Stock</span></h6>;
            stockavail = '';
        }

        if(product.original_price == '' || product.original_price == null)
        {
            itemPrice =
            <h2 className="dolar">${product.selling_price}</h2>
        }
        else{
            itemPrice =
            <h2 className="dolar">${product.selling_price} <del>{product.original_price}</del></h2>
        }
    }


    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-title-content text-center">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/collection">Collection</Link></li>
                                            <li><Link to={`/collection/${product.category.slug}`}>{product.category.name}</Link></li>
                                            <li className="blog">{product.name}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="main-shop-detail-review">
                <section className="main-shop-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={`${base_path}${imgpath}`} 
                                width='' 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src=`${base_path}${cateimgpath}`;
                                }}
                                />
                            </div>
                            <div className="col-lg-6 text-left">
                                <div className="content-wrap">
                                    <h2 className="heading"><Link to="">{product.name} - {product.size}</Link></h2>
                                    {stockavailbadge}
                                    <div className="content-text">
                                        {product.description ? parse(product.description) : ''}
                                    </div>
                                    {itemPrice}
                                    
                                    <div className="info-content">
                                        <ul>
                                            <li>
                                                <span>
                                                    <span className="note">Category:</span> {product.category.name}
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <span className="note">Tags:</span> {product.meta_keywords}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    {stockavail}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}