import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BasePath } from "../App";

export default function Collection() {

    const base_path = useContext(BasePath);
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState([]);
    useEffect(() =>{
        let isMountered = true;
        axios.get(`/api/getCategory`).then(res=>{
            if(isMountered)
            {
                if(res.data.status === 200){
                    // console.log(res.data.category);
                    setCategory(res.data.category);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMountered = false;
        }
    }, [history]);

    if(loading){
        return <p>Loading Collection...</p>
    }
    else{
        var showCategoryList = '';
        showCategoryList = category.map( (item, idx) => {
            var imgpath = item.thumbnail;
            imgpath = imgpath.substr(imgpath.indexOf("/"));
            return (
                <li className="product-item features arrivals" key={idx}>
                    <div className="product-thumb clearfix">
                        <Link to={item.slug} className="product-thumb">
                            <img src={`${base_path}${imgpath}`} 
                            width="60" alt="image" 
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="./assets/image/homepage14.png";
                            }}
                            />
                        </Link>
                    </div>
                    <div className="product-info text-center clearfix">
                        <span className="product-title">{item.name}</span>
                    </div>
                </li>
            )
        });
    }


    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-title-content text-center">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li className="blog"><Link to="#">Collection</Link></li>
                                        </ul>
                                    </div>
                                    <div className="page-title-heading">
                                        <h2 className="title"><Link to="#">Collection</Link></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <section className="flat-products style3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="product-content product-fourcolumn clearfix">
                                <ul className="product style2 isotope-product clearfix">
                                    {showCategoryList}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}