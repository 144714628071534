import React from "react";
import { Link } from "react-router-dom";
// import Footer from "./Footer";
// import Header from './Header';

export default function About() {
    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-title-content text-center">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li className="blog"><a href="#">About Us</a></li>
                                        </ul>
                                    </div>
                                    <div className="page-title-heading">
                                        <h2 className="title"><a href="#">ABOUT US</a></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="main-about">
                <section className="flat-about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-5">
                                <img src="/assets/image/homepage142.png" alt="image" />
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="about-us-border">
                                    <h1 className="heading-about"><a href="#">Welcome To NorthShore Wine & Spirits</a></h1>
                                    <p className="content-about">We are operating as a family owned business, our goal is to greet the customer like family. Northshore Wine & Spirits in Highwood, IL is committed to having the lowest prices on wine, spirits and beer every day to make your shopping experience pleasant. If there is a product that you seek and cannot find let us know and will get it for you. (typically in one week based on the availability).</p>
                                    <p className="content-about">Established in 2006. <br/>We are here to serve the local community as a one stop shop discount liquor store. we're the leader in providing premium wine, spirits and beer. We Hope to continue doing so in future providing customers with premium Products at the best price available.</p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </section>
            </div>
        </React.Fragment>
    )
}