import React from "react";
import { Link } from "react-router-dom";

export default function Shop() {
    return(
        <React.Fragment>
            <div className="page-title parallax parallax1 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-title-content text-center">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li className="blog"><Link to="#">Products</Link></li>
                                        </ul>
                                    </div>
                                    {/* <div className="page-title-heading">
                                        <h2 className="title"><Link to="#">ARCHIVES PRODUCTS</Link></h2>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="main-shop-right grid">
                <div className="container">
                    <div className="row">
                            <div className="col-lg-9">
                                    <div className="filter-shop clearfix">
                                        <ul>
                                            <li className="grid"><Link to="#"><i className="fa fa-th-large" aria-hidden="true"></i></Link></li>
                                            <li className="list"><Link to="#"><i className="fa fa-list" aria-hidden="true"></i></Link></li>
                                        </ul>
                                        <span className="filter-icon"><i className="fa fa-caret-down" aria-hidden="true"></i></span>
                                        <span className="filter-right">Sort by price: low to high</span>                    
                                </div>
                                <div className="product-content clearfix shop-right-grid">
                                            <ul className="product style2 isotope-product clearfix">
                                            <li className="product-item sale">
                                                <div className="product-thumb clearfix">
                                                    <Link to="#" className="product-thumb">
                                                        <img src="assets/image/homepage59.png" alt="image" />
                                                    </Link>
                                                </div>
                                                <div className="product-info text-center clearfix">
                                                    <span className="product-title">1Point Red Wine Trio</span>
                                                    
                                                    <div className="price">
                                                        <ins>
                                                            <span className="amount">$33.99</span>
                                                        </ins>
                                                    </div>
                                                    <div className="elm-btn">
                                                    <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="product-review">
                                                        <div className="add-cart">
                                                            <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like2"><i className="fa fa-heart-o"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                                                        </div>
                                                </div>
                                            </li>
                                            <li className="product-item arrivals sale">
                                                <div className="product-thumb clearfix">
                                                    <Link to="#" className="product-thumb">
                                                        <img src="assets/image/homepage60.png" alt="image" />
                                                    </Link>
                                                </div>
                                                <div className="product-info clearfix">
                                                    <span className="product-title">Josh Cellar Cabenet</span>
                                                    <div className="price">
                                                        <ins>
                                                            <span className="amount">$29.99</span>
                                                        </ins>
                                                    </div> 
                                                    <div className="elm-btn">
                                                        <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                                                    </div>    
                                                </div>
                                                <div className="product-review">
                                                        <div className="add-cart">
                                                            <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like2"><i className="fa fa-heart-o"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                                                        </div>
                                                </div>
                                            </li>
                                            <li className="product-item sale">
                                                <div className="product-thumb clearfix">
                                                    <Link to="#" className="product-thumb">
                                                        <img src="assets/image/homepage61.png" alt="image" />
                                                    </Link>
                                                    <span className="new">Sale</span>
                                                </div>
                                                <div className="product-info clearfix">
                                                    <span className="product-title">Dreaming Wine Trio</span>
                                                    <div className="price">                             
                                                        <ins>
                                                            <span className="amount">$38.99</span>
                                                            <del>$38.99</del>
                                                        </ins>
                                                    </div>
                                                    <div className="elm-btn">
                                                        <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="product-review">
                                                        <div className="add-cart">
                                                            <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like2"><i className="fa fa-heart-o"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                                                        </div>
                                                </div>
                                            </li>
                                            <li className="product-item sale">
                                                <div className="product-thumb clearfix">
                                                    <Link to="#" className="product-thumb">
                                                        <img src="assets/image/homepage61.png" alt="image" />
                                                    </Link>
                                                    <span className="new">Sale</span>
                                                </div>
                                                <div className="product-info clearfix">
                                                    <span className="product-title">Dreaming Wine Trio</span>
                                                    <div className="price">                             
                                                        <ins>
                                                            <span className="amount">$38.99</span>
                                                            <del>$38.99</del>
                                                        </ins>
                                                    </div>
                                                    <div className="elm-btn">
                                                        <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="product-review">
                                                        <div className="add-cart">
                                                            <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like2"><i className="fa fa-heart-o"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                                                        </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="product-content clearfix">
                                            <ul className="product style2 isotope-product clearfix">
                                            <li className="product-item sale">
                                                <div className="product-thumb clearfix">
                                                    <Link to="#" className="product-thumb">
                                                        <img src="assets/image/homepage59.png" alt="image" />
                                                    </Link>
                                                </div>
                                                <div className="product-info text-center clearfix">
                                                    <span className="product-title">2Point Red Wine Trio</span>
                                                    <div className="price">
                                                        <ins>
                                                            <span className="amount">$33.99</span>
                                                        </ins>
                                                    </div>
                                                    <div className="elm-btn">
                                                        <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="product-review">
                                                        <div className="add-cart">
                                                            <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like2"><i className="fa fa-heart-o"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                                                        </div>
                                                </div>
                                            </li>
                                            <li className="product-item arrivals sale">
                                                <div className="product-thumb clearfix">
                                                    <Link to="#" className="product-thumb">
                                                        <img src="assets/image/homepage60.png" alt="image" />
                                                    </Link>
                                                </div>
                                                <div className="product-info clearfix">
                                                    <span className="product-title">Josh Cellar Cabenet</span>
                                                    <div className="price">
                                                        <ins>
                                                            <span className="amount">$29.99</span>
                                                        </ins>
                                                    </div> 
                                                    <div className="elm-btn">
                                                        <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                                                    </div>    
                                                </div>
                                                <div className="product-review">
                                                        <div className="add-cart">
                                                            <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like2"><i className="fa fa-heart-o"></i></Link>
                                                        </div>
                                                        <div className="add-cart">
                                                            <Link to="#" className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                                                        </div>
                                                </div>
                                            </li> 
                                            <li className="product-item sale">
                                                <div className="product-thumb clearfix">
                                                    <Link to="#" className="product-thumb">
                                                        <img src="assets/image/homepage61.png" alt="image" />
                                                    </Link>
                                                    <span className="new">Sale</span>
                                                </div>
                                                <div className="product-info clearfix">
                                                    <span className="product-title">Dreaming Wine Trio</span>
                                                    <div className="price">                             
                                                        <ins>
                                                            <span className="amount">$38.99</span>
                                                            <del>$38.99</del>
                                                        </ins>
                                                    </div>
                                                    <div className="elm-btn">
                                                        <Link to="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontal">BUY NOW</Link>
                                                    </div>
                                                </div>
                                                <div className="product-review">
                                                    <div className="add-cart">
                                                        <Link to="#" className="like1"><i className="fa fa-refresh" aria-hidden="true"></i></Link>
                                                    </div>
                                                    <div className="add-cart">
                                                        <Link to="#" className="like2"><i className="fa fa-heart-o"></i></Link>
                                                    </div>
                                                    <div className="add-cart">
                                                        <Link to="#" className="like3"><i className="fa fa-shopping-cart" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="blog-pagination text-center">
                                        <ul className="flat-pagination">
                                            <li className="prev"><Link to="#" className=" hvr-shutter-out-horizontal"> <i className="fa fa-angle-left" aria-hidden="true"></i> </Link></li>
                                            <li><Link to="#" className=" hvr-shutter-out-horizontal"> 1 </Link></li>
                                            <li><Link to="#" className=" hvr-shutter-out-horizontal"> 2 </Link></li>
                                            <li><Link to="#" className=" hvr-shutter-out-horizontal"> 3 </Link></li>
                                            <li className="next"><Link to="#" className=" hvr-shutter-out-horizontal"> <i className="fa fa-angle-right" aria-hidden="true"></i> </Link></li>
                                        </ul>
                                    </div> 
                            </div>
                            <div className="col-lg-3 sidebar">
                                <div className="sidebar-inner clearfix">
                                        <div className="widget widget-search">
                                            <form action="#" id="searchform" method="get">
                                                <div>
                                                    <input type="text" id="s" className="sss" placeholder="Search ..." />
                                                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                                </div>
                                            </form>
                                        </div> 
                                        <div className="widget widget-categories">
                                            <h2 className="widget-title text-center">Categories</h2>
                                            <ul>  
                                                    <li className="thumb-new-categories categorie">
                                                        <Link to="#"> 
                                                            Red Wine 
                                                            <span className="categories-time"> (18)</span>
                                                        </Link>
                                                        <img src="assets/image/dot.jpg" alt="image" />
                                                    </li>
                                                    <li className="thumb-new-categories">
                                                        <Link to="#"> 
                                                            Wines Store
                                                            <span className="categories-time"> (27)</span>
                                                        </Link>
                                                        <img src="assets/image/dot.jpg" alt="image" />
                                                    </li>
                                                    <li className="thumb-new-categories">
                                                        <Link to="#"> 
                                                            Wine Gold
                                                            <span className="categories-time"> (18)</span>
                                                        </Link>
                                                        <img src="assets/image/dot.jpg" alt="image" />
                                                    </li>
                                                    <li className="thumb-new-categories">
                                                        <Link to="#">
                                                            Champagne Red
                                                            <span className="categories-time"> (23)</span>
                                                        </Link>
                                                    </li>
                                            </ul>
                                        </div>
                                        <div className="widget widget-filter">
                                            <h2 className="widget-title text-center">Filter By Price</h2>
                                            <div className="price-filter">
                                                    <div id="slide-range"></div>
                                                    <span className="filter"><Link to="#" className="hvr-shutter-out-horizontal">FILTER</Link></span>
                                                    <p className="amount">
                                                    Price: <span id="amount">
                                                        
                                                    </span>
                                                    </p>
                                            </div> 
                                                <div className="thumb-new-banner">
                                                    <div className="banner-content">
                                                        <div className="banner-title">
                                                            <h3>Local</h3>
                                                        </div>
                                                        <div className="banner-contact">
                                                            <h1>Wine Shop</h1>
                                                        </div>
                                                        <div className="banner-button">
                                                            <Link to="#" className="hvr-shutter-out-horizontal">BUY NOW</Link>
                                                        </div>
                                                    </div>
                                                    <img src="assets/image/banner.jpg" alt="image" />
                                                </div>    
                                        </div>
                                        <div className="widget widget-recent clearfix">
                                            <h2 className="widget-title text-center">Recent Product</h2> 
                                                <ul>  
                                                    <li className="thumb-new">
                                                        <div className="thumb-new-image">
                                                            <img src="assets/image/c1.jpg" alt="image" />
                                                        </div>  
                                                        <div className="thumb-new-content">
                                                            <ul>
                                                                <li> <span className="thumb-new-title">Laboure Chanrdonnay</span> </li>
                                                                <li> <span className="thumb-new-price">$19.99</span> </li>
                                                                <li> <span className="thumb-new-rating">
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                    </span> 
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li className="thumb-new thumb">
                                                        <div className="thumb-new-image">
                                                            <img src="assets/image/c2.jpg" alt="image" />
                                                        </div>  
                                                        <div className="thumb-new-content">
                                                            <ul>
                                                                <li> <span className="thumb-new-title">Godiva Chocolates</span> </li>
                                                                <li> <span className="thumb-new-price">$28.99</span> </li>
                                                                <li> <span className="thumb-new-rating">
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                                    </span> 
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li> 
                                                    <li className="thumb-new thumb">
                                                        <div className="thumb-new-image">
                                                            <img src="assets/image/c3.jpg" alt="image" />
                                                        </div>  
                                                        <div className="thumb-new-content">
                                                            <ul>
                                                                <li> <span className="thumb-new-title">Bertrand Cap Red</span> </li>
                                                                <li> <span className="thumb-new-price">$22.99</span> </li>
                                                                <li> <span className="thumb-new-rating">
                                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                                    </span> 
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>  
                                                </ul>    
                                        </div>
                                        <div className="widget widget-tag">
                                                        <h2 className="widget-title text-center">Popular Tags</h2>  
                                                        <div className="widget-tag-inner tag-inner widget-tag-list">
                                                            <ul>
                                                                <li className="tag-inner"><Link to="#" className="hvr-shutter-out-horizontal">Champagne</Link></li>
                                                                <li className="tag-content"><Link to="#" className="hvr-shutter-out-horizontal">Red Wine</Link></li>
                                                                <li className="tag-contact"><Link to="#" className="hvr-shutter-out-horizontal">Wine</Link></li>
                                                                <li className="tag-inner"><Link to="#" className="hvr-shutter-out-horizontal">Cider</Link></li>
                                                                <li className="tag-content"><Link to="#" className="hvr-shutter-out-horizontal">Vine Yard</Link></li>
                                                                <li className="tag-contact"><Link to="#" className="hvr-shutter-out-horizontal">Dessert</Link></li>         
                                                                <li className="tag-inner"><Link to="#" className="hvr-shutter-out-horizontal">White</Link></li>
                                                                <li className="tag-content"><Link to="#" className="hvr-shutter-out-horizontal">Red</Link></li>
                                                                <li className="tag-contact"><Link to="#" className="hvr-shutter-out-horizontal">Spritzer</Link></li>
                                                            </ul>
                                                        </div>
                                        </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}