import React from "react";
// import Footer from "./Footer";
// import Header from './Header';

export default function ProductDetails() {
    return(
        <React.Fragment>
                <div className="page-title parallax parallax1 ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="page-title-content text-center">
                                        <div className="breadcrumbs">
                                            <ul>
                                                <li><a href="#">Home</a></li>
                                                <li><a href="#">Product</a></li>
                                                <li className="blog"><a href="#">Product Details</a></li>
                                            </ul>
                                        </div>
                                        <div className="page-title-heading">
                                            <h2 className="title"><a href="#">PRODUCT DETAILS</a></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="main-shop-detail-review">
                    <section className="main-shop-detail">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image-wrap">
                                        <div className="image-rallary mg-bottom-45">
                                            <div className="themesflat-gallery  style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w170  clearfix" data-gap="0" data-column="1" data-column2="1" data-column3="1" data-auto="false">
                                                <div className="owl-carousel owl-theme">
                                                    <div className="gallery-item" >
                                                        <div className="inner">
                                                            <div className="thumb">
                                                                <img src="./assets/image/homepage152.png" alt="Image" />
                                                                <div className="search-images">
                                                                    <a data-type="iframe" href="./assets/image/homepage127.png" className="zoom-pic" >
                                                                        <i className="icon_zoom-in_alt "  aria-hidden="true"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gallery-item" >
                                                        <div className="inner">
                                                            <div className="thumb">
                                                                <img src="./assets/image/homepage152.png" alt="Image" />
                                                                <div className="search-images">
                                                                    <a data-type="iframe" href="./assets/image/homepage127.png" className="zoom-pic" >
                                                                        <i className="icon_zoom-in_alt "  aria-hidden="true"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gallery-item active" >
                                                        <div className="inner">
                                                            <div className="thumb">
                                                                <img src="./assets/image/homepage152.png" alt="Image" />
                                                                <div className="search-images">
                                                                    <a data-type="iframe" href="./assets/image/homepage127.png" className="zoom-pic" >
                                                                        <i className="icon_zoom-in_alt "  aria-hidden="true"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="content-wrap">
                                        <h2 className="heading"><a href="">Vivonne sauvignon 1988</a></h2>
                                        <div className="post-rating">
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                        </div>
                                        <h2 className="dolar">$19.99</h2>
                                        <div className="content-text">
                                            <p>A New Generation of Winernakers Lorem ipsum dolor sit amet sectetu adipiscing elit. Aenean commodo ligula eget dolor.</p>
                                        </div>
                                        <div className="info-content">
                                            <ul>
                                                <li>
                                                    <span>
                                                        <span className="note">SKU: </span> 01NA55
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        <span className="note">Categories:</span> Wines, Grapes
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        <span className="note">Tags:</span> Champange, Wine, Red
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="size">
                                            <span>Size</span>
                                            <select>
                                                <option value="volvo">Choosing Bottle Sizes</option>
                                                <option value="saab">Saab</option>
                                                <option value="opel">Opel</option>
                                                <option value="audi">Audi</option>
                                            </select>
                                        </div>
                                        <div className="content-cart clearfix">
                                            <form className="cart clearfix" method="post">
                                                <div className="quantity">
                                                    <input type="number" step="1" min="1" name="quantity" title="Qty" className="input-text qty text" size="4" />
                                                </div>                                    
                                                <div className="elm-btn">
                                                    <a href="#" className="themesflat-button outline ol-accent margin-top-40 hvr-shutter-out-horizontall"><i className="fa fa-shopping-cart" aria-hidden="true"></i> ADD TO CART</a>
                                                </div>
                                                <div className="heart">
                                                    <i className="fa fa-heart" aria-hidden="true"></i>
                                                </div>
                                            </form> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-11">
                                    <div className="woocommerce-tabs wc-tabs-wrapper">
                                            <ul className="tabs">
                                                <li><a className="tab active" data-id ='#tab-description' href="#">Description</a></li>
                                                <li><a className="tab" data-id ='#tab-reviews' href="#">Reviews (1)</a></li>
                                            </ul>
                                            <div id="tab-description" className="tab-content">
                                                <p>This wine is deeply coloured and brightly scented with intense aromas of cassis, dark cherries and dried herbs underpinned by chocolate and mocha-like oak.</p>
                                                <p>   - Size: 500ml, 750ml, 1,5L</p>
                                                <p>   - Vintage: 1897, 1988</p>
                                                <p>  - Taste: Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                                <p> This wine was blended from two parcels of fruit grown by a fourth-generation vigneron. The vines are aged from 10 to 15 years and are trained on a vertical shoot positioning trellis.</p>
                                            </div>
                                            <div id="tab-reviews" className="tab-content">
                                                <div id="reviews" className="woocommerce-Reviews">
                                                    <div className="post-bottom-second post-bottom">
                                                        <div className="avatar">
                                                        <img src="image/comment1.jpg" alt="image" />
                                                        <div className="post-rating">
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <div className="post-comment">
                                                            <div className="comment-meta comment"><a href="#"> May 28, 2018</a></div>
                                                            <div className="comment-title comment"><a href="#">Brandon Kelley</a></div>
                                                            <div className="comment-text comment">
                                                            <a href="#">Vasse Felix chief executive, Paul Holmes a Court said no plans have yet been made for the buildings on the property</a>
                                                            </div>
                                                        </div>
                                                </div>
                                                    <div className="comment-respond">
                                                    <form className="comment-form">
                                                            <p><a href="#">Add Preview</a> </p>
                                                            <p className="comment-form-author">
                                                                <input name="author" type="text" placeholder="Name" required="required" />
                                                            </p>
                                                            <p className="comment-form-email">
                                                                <input name="email" type="email" placeholder="Email" required="required" />
                                                            </p>
                                                            <div className="clearfix"></div>
                                                            <p className="your-rating"><a href="#">Your Rating</a> </p>
                                                            <div className="post-rating">
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                            </div>
                                                            <p className="comment-form-comment">
                                                                <textarea  name="comment" placeholder="Comment" required="required"></textarea>
                                                            </p>
                                                            <p className="submit-form">
                                                                <button name="submit" type="submit"  className="submit btn btn-styl hvr-shutter-out-horizontal" >SUBMIT</button>
                                                            </p>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        </React.Fragment>
    )
}