import axios from "axios";
import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartCounter } from "../App";

export default function Login() {

    const cartTot = useContext(CartCounter);
    const history = useNavigate ();
    const [loginInput, setLogin] = useState({
        email: '',
        password: '',
        error_list: [],
    });
    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: loginInput.email,
            password: loginInput.password,
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`api/login`, data).then(res => {
                if(res.data.status === 200){
                    cartTot.setCartVal(res.data.cartCount);
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    toast.success(res.data.message, {
                        position: "top-center"
                    });
                    history('/');
                } else if(res.data.status === 401){
                    toast.warn(res.data.message, {
                        position: "top-center"
                    });
                } else {
                    setLogin({...loginInput, error_list: res.data.validation_errors});
                }
            });
        });
    }

    return(
        <React.Fragment>
            <div className="main-about">
                <section className="flat-about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="title-section">
                                    <h3 className="our-product-title"><Link to="#"> Sign In </Link></h3>
                                    <h1 className="our-product-main"><Link to="#"> Please sign in to your account </Link></h1>
                                    <div className="our-product-image">
                                        <img src="./assets/image/homepage14.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <img src="./assets/image/login.webp" className="img-responsive" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 text-left">
                                <br /><br/>
                                <form onSubmit={loginSubmit}>
                                    <div className="form-group">
                                        <label>Email address</label>
                                        <input type="email" name="email" onChange={handleInput} value={loginInput.email} className="form-control" placeholder="Enter email" />
                                        <span className="text-danger">{loginInput.error_list.email}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" name="password" onChange={handleInput} value={loginInput.password} className="form-control" placeholder="Password" />
                                        <span className="text-danger">{loginInput.error_list.password}</span>
                                    </div>
                                    <h4 className="text-right"><Link to="#">Forgot Password?</Link></h4><br/>
                                    <button type="submit" className="btn btn-primary form-control">Login</button>
                                    <h4 className="text-center">Or</h4>
                                    <h4 className="text-center">Don't have an account <Link to="/register">Register here</Link></h4>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}