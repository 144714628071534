import React from "react";
import { Link } from "react-router-dom";

export default function Slider() {
    return(
        <React.Fragment>
            <div className="flat-slider style1">
                <div className="rev_slider_wrapper fullwidthbanner-container" >
                    <div id="rev-slider1" className="rev_slider fullwidthabanner text-left">
                        <ul>                            
                            <li data-transition="random">
                                
                                <img src="/assets/image/homepage11.jpg" alt="" data-bgposition="center center" data-no-retina />
                                <div className="overlay"></div>

                                <div className="tp-caption tp-resizeme text-ffb922 font-rubik font-weight-500 wellcome"
                                data-x="['left','left','left','center']" data-hoffset="['2','4','4','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['-132','-118','-98','-78']"
                                data-fontsize="['36','20','20','16']"
                                data-lineheight="['48','48','28','14']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                                data-mask_in="x:0px;y:[100%];" 
                                data-mask_out="x:inherit;y:inherit;" 
                                data-start="700" 
                                data-splitin="none" 
                                data-splitout="none" 
                                data-paddingleft= "['3','3','3','3']"
                                data-responsive_offset="on">Welcome to Northshore</div>

                                <div className="tp-caption tp-resizeme font-rubik font-weight-700 best"
                                data-x="['left','left','left','center']" data-hoffset="['-4','-4','-4','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['-60','-60','-50','-40']"
                                data-fontsize="['72','60','40','32']"
                                data-lineheight="['80','80','45','35']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                data-mask_in="x:0px;y:[100%];" 
                                data-mask_out="x:inherit;y:inherit;" 
                                data-start="1000" 
                                data-splitin="none" 
                                data-splitout="none" 
                                data-responsive_offset="on"> <Link to="/collection/wine" className="text-white">Best Of Wine Store</Link> </div>

                                <div className="tp-caption tp-resizeme text-white font-rubik font-weight-400 text-wizym"
                                data-x="['left','left','left','center']" data-hoffset="['0','-2','-2','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['27','27','27','17']"
                                data-fontsize="['18','18','16','14']"
                                data-lineheight="['32','32','26','22']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                                data-mask_in="x:0px;y:[100%];" 
                                data-mask_out="x:inherit;y:inherit;" 
                                data-start="1000" 
                                data-splitin="none" 
                                data-splitout="none" 
                                data-responsive_offset="on" 
                                data-paddingright="['550','155','50','2']" >Northshore offers extensive collections of over 2,000 unique wines from prestigious brands to family-owed wineries </div>

                                <div className="tp-caption"
                                data-x="['left','left','left','center']" data-hoffset="['0','-4','-4','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['102','102','92','70']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                                data-mask_in="x:0px;y:[100%];" 
                                data-mask_out="x:inherit;y:inherit;" 
                                data-start="1000" 
                                data-splitin="none" 
                                data-splitout="none" 
                                data-responsive_offset="on" 
                                data-paddingtop= "['50','50','50','50']"
                                data-paddingbottom= "['50','50','50','50']"> <Link to="/collection/wine" className="btn btn-styl button-project">READ MORE</Link></div>
                            </li>                            
                            <li data-transition="random">
                                
                                <img src="/assets/image/homepage11.jpg" alt="" data-bgposition="center center" data-no-retina />
                                <div className="overlay"></div>

                                
                                <div className="tp-caption tp-resizeme text-ffb922 font-rubik font-weight-500 wellcome"
                                data-x="['left','left','left','center']" data-hoffset="['2','4','4','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['-132','-118','-98','-78']"
                                data-fontsize="['36','20','20','16']"
                                data-lineheight="['48','48','28','14']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                                data-mask_in="x:0px;y:[100%];" 
                                data-mask_out="x:inherit;y:inherit;" 
                                data-start="700" 
                                data-splitin="none" 
                                data-splitout="none" 
                                data-paddingleft= "['3','3','3','3']"
                                data-responsive_offset="on">Welcome to Northshore  </div>

                                <div className="tp-caption tp-resizeme font-rubik font-weight-700 best"
                                data-x="['left','left','left','center']" data-hoffset="['-4','-4','-4','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['-60','-60','-50','-40']"
                                data-fontsize="['72','60','40','32']"
                                data-lineheight="['80','80','45','35']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                data-mask_in="x:0px;y:[100%];" 
                                data-mask_out="x:inherit;y:inherit;" 
                                data-start="1000" 
                                data-splitin="none" 
                                data-splitout="none" 
                                data-responsive_offset="on"> <Link to="#" className="text-white">Best Of Wine Store</Link> </div>

                                <div className="tp-caption tp-resizeme text-white font-rubik font-weight-400 text-wizym"
                                data-x="['left','left','left','center']" data-hoffset="['0','-2','-2','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['27','27','27','17']"
                                data-fontsize="['18','18','16','14']"
                                data-lineheight="['32','32','26','22']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                                data-mask_in="x:0px;y:[100%];" 
                                data-mask_out="x:inherit;y:inherit;" 
                                data-start="1000" 
                                data-splitin="none" 
                                data-splitout="none" 
                                data-responsive_offset="on" 
                                data-paddingright="['550','155','50','2']" >Northshore offers extensive collections of over 2,000 unique wines from prestigious brands to family-owed wineries </div>

                                <div className="tp-caption"
                                data-x="['left','left','left','center']" data-hoffset="['0','-4','-4','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['102','102','92','70']"
                                data-width="full"
                                data-height="none"
                                data-whitespace="normal"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                                data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                                data-mask_in="x:0px;y:[100%];" 
                                data-mask_out="x:inherit;y:inherit;" 
                                data-start="1000" 
                                data-splitin="none" 
                                data-splitout="none" 
                                data-responsive_offset="on" 
                                data-paddingtop= "['50','50','50','50']"
                                data-paddingbottom= "['50','50','50','50']"> <Link to="/collection/wine" className="btn btn-styl button-project">READ MORE</Link></div>
                            </li>
                        </ul>
                    </div>
                </div> 
            </div>
        </React.Fragment>
    )
}