import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

export default function Footer() {
    return(
        <React.Fragment>
            <footer className="style3">
                <div className="container">
                    <div className="footer-widgets">
                        <div className="widget widget-logo">
                            <Link to="/" className="logo"><img src="/assets/image/logo.png" alt="image" width="129" height="37" data-retina="/assets/image/logo-2x.png" data-width="147" data-height="21" /></Link>
                        </div> 
                        <div className="widget widget-icon">
                            <ul>
                                <li className="active"><Link to="#"> <i className="fa fa-facebook" aria-hidden="true"></i> </Link></li>
                                <li><Link to="#"> <i className="fa fa-twitter" aria-hidden="true"></i> </Link></li>
                                <li><Link to="#"> <i className="fa fa-instagram" aria-hidden="true"></i> </Link></li>
                                <li><Link to="#"> <i className="fa fa-skype" aria-hidden="true"></i> </Link></li>
                            </ul>
                        </div>
                        <div className="widget widget-title">
                            <h2>Get news & offers</h2>
                        </div>
                        {/* <div className="widget widget-contact">
                            <input type="search" id="search-footer" placeholder="Your Email" />
                            <button type="submit"><i className="fa fa-location-arrow" aria-hidden="true"></i></button>
                        </div> */}
                        <div className="widget widget-menu">
                            <ul>
                                <li><Link to="/about"> About Us </Link></li>
                                <li><Link to="/contact"> Customer Service </Link></li>
                                <li><Link to="/terms-and-conditions"> Terms & Conditions </Link></li>
                                <li><Link to="/privacy-policy"> Privacy Policy </Link></li>
                                <li className="contact"><Link to="/contact"> Contact </Link></li>
                            </ul>
                        </div> 
                        <div className="widget widget-text">
                            <span>© 2022 Created by <a href="https://www.arinfosoft.com/">AR Infosoft</a>. All Rights Reserved</span>
                        </div>
                    </div>
                </div>
            </footer>
            <Link to="#" id="scroll-top"><i className="fa fa-angle-right" aria-hidden="true"></i></Link>
            <ToastContainer />
        </React.Fragment>
    )
}